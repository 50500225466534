/* TYPOGRAPHY */
@font-face {
  font-family: "BentonSansBBVA";
  src: font_url("/src/assets/fonts/BentonSansBBVA-Bold.woff2") format("woff2");
}
$custom-typography: mat-typography-config(
  $font-family: "BentonSansBBVA, Roboto, monospace",
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500),
);
