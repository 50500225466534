@import "./styles/theme.scss";

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  min-width: 250px;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

canvas {
  width: 100% !important;
}

.mat-header-cell {
  flex-direction: column;
  justify-content: center;
}

.mat-cell {
  text-align: center;
  justify-content: center;
}
